// Debug setup
// export const DOMAIN = '127.0.0.1:8000' 
// export const BASEURL = "http://"+DOMAIN
// export const APP_SOCKET_PATH = "ws://"+DOMAIN+"/ws/valuebet?token="
// export const CHAT_SOCKET_PATH = "ws://"+DOMAIN+"/ws/chat/?token="

// Local setup
// export const DOMAIN = "127.0.0.1:8000"
// export const W_DOMAIN = "127.0.0.1:8001"
// export const BASEURL = "http://"+DOMAIN
// export const APP_SOCKET_PATH = "ws://"+W_DOMAIN+"/ws/valuebet?token="
// export const CHAT_SOCKET_PATH = "ws://"+W_DOMAIN+"/ws/chat/?token="

// Live setup
export const DOMAIN = 'dev-server.valuebet.app'
export const BASEURL = "https://"+DOMAIN
export const APP_SOCKET_PATH = "wss://"+DOMAIN+"/ws/valuebet?token="
export const CHAT_SOCKET_PATH = "wss://"+DOMAIN+"/ws/chat/?token="
